// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

$primary-color: #066469;
$secondary-color: #328387;
$background-color: #ffffff;
$hover-color: #e5f6f7;
$transition-duration: 0.3s;
$icon-color: #298c91;
$shadow-color: rgba(84, 197, 203, 0.1);
$overlay-color: rgba(0, 0, 0, 0.5);

// Font Variables
$font-bodoni: 'Libre Bodoni', serif;

// Breakpoints
$mobile: 768px;
$tablet: 1024px;
$desktop: 1200px;

@mixin mobile {
  @media (max-width: #{$mobile - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

// Animations
@keyframes rotateIn {
  from {
    transform: rotate(-180deg);
    opacity: 0;
  }
  to {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateOut {
  from {
    transform: rotate(0);
    opacity: 1;
  }
  to {
    transform: rotate(180deg);
    opacity: 0;
  }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; visibility: hidden; }
}

@keyframes zoomIn {
  from { transform: scale(0.5); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes unfold {
  0% {
    transform: perspective(500px) rotateY(90deg) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: perspective(500px) rotateY(45deg) scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: perspective(500px) rotateY(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

// Intro Animation
.intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeOut 0.5s ease-out 2.5s forwards;
}

.intro-logo {
  width: 200px;
  height: auto;
  animation: zoomIn 2s ease-out;
  font-family: $font-bodoni;
}

// Navbar Styles
.navbar {
  background-color: $background-color;
  box-shadow: 0 2px 4px $shadow-color;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
  font-family: $font-bodoni;
  
  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  &__container {
    max-width: $desktop;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    height: 70px;
    position: relative;
    width: 90%;

    @include mobile {
      padding: 0 0.75rem;
      height: 60px;
      justify-content: space-between;
    }
  }

  .animated-banner {
    position: sticky;
    top: 0;
    z-index: 1001;
    width: 100%;
  }

  &__logo {
    margin-left: 1rem;
    font-family: $font-bodoni;

    @include mobile {
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &-img {
      height: 50px;
      width: auto;

      @include mobile {
        height: 40px;
      }
    }

    &.animated-logo {
      animation: unfold 1.5s ease-out;
    }
  }

  &__sidebar-toggle {
    border: none;
    background: transparent;
    padding: 0.75rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    
    @include mobile {
      padding: 0.5rem;
      margin-right: 0;
    }

    &:hover {
      background-color: $hover-color;
    }

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
        color: $primary-color !important;
        transition: all 0.2s ease-in-out;

        &.rotating-enter {
          animation: rotateIn 0.2s ease-in-out;
        }

        &.rotating-exit {
          animation: rotateOut 0.2s ease-in-out;
        }
      }
    }

    &:hover .icon-container svg {
      color: darken($primary-color, 10%) !important;
    }
  }

  &__user-btn {
    &:hover {
      background-color: $hover-color;
      color: darken($primary-color, 10%);
    }
  }

  &__links {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include mobile {
      display: none;
    }
  }

  &__link {
    color: $secondary-color;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all $transition-duration ease;
    font-weight: 500;
    font-size: 0.9375rem;
    font-family: $font-bodoni;

    &:hover {
      color: $primary-color;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    gap: 0.75rem;

    @include mobile {
      margin-left: auto;
      gap: 0.5rem;
    }
  }

  &__action-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: $primary-color;
    transition: all $transition-duration ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-bodoni;

    @include mobile {
      padding: 0.35rem;
    }

    &:hover {
      color: darken($primary-color, 10%);
      background-color: $hover-color;
      border-radius: 0.375rem;
    }

    .navbar__icon {
      width: 22px;
      height: 22px;
      fill: currentColor;

      @include mobile {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__menu-btn {
    @extend .navbar__action-btn;
    display: none;

    @include mobile {
      display: flex;
    }
  }

  &--mobile {
    .navbar__container {
      justify-content: space-between;
    }

    .navbar__left {
      display: flex;
      align-items: center;
    }

    .navbar__actions--mobile {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    background-color: $background-color;
    transform: translateX(100%);
    transition: transform $transition-duration ease;
    z-index: 1001;
    visibility: hidden;
    box-shadow: -2px 0 4px $shadow-color;
    font-family: $font-bodoni;

    &.is-open {
      transform: translateX(0);
      visibility: visible;
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $overlay-color;
      opacity: 0;
      visibility: hidden;
      transition: opacity $transition-duration ease;
      z-index: 1000;

      .is-open & {
        opacity: 1;
        visibility: visible;
      }
    }

    &-content {
      padding: 2rem 1.5rem;
      height: 100%;
      overflow-y: auto;
    }

    &__user-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #066469;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      font-family: $font-bodoni;
    }
  
    &__user-menu {
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      padding: 12px;
      min-width: 200px;
      font-family: $font-bodoni;
  
      &-container {
        position: absolute;
        top: 60px;
        right: 16px;
        z-index: 1000;
      }
    }
  
    &__user-info {
      padding: 8px 4px;
      border-bottom: 1px solid #eee;
      margin-bottom: 8px;
    }
  
    &__user-mobile {
      font-weight: 500;
      color: #066469;
    }
  
    &__menu-item {
      width: 100%;
      padding: 8px 12px;
      text-align: left;
      border: none;
      background: none;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 4px;
      color: #333;
      font-size: 14px;
      font-family: $font-bodoni;
  
      &:hover {
        background-color: #f5f5f5;
        color: #066469;
      }
    }

    &-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      transition: all $transition-duration ease;
      font-family: $font-bodoni;

      &:hover {
        background-color: $hover-color;
        color: darken($primary-color, 10%);
      }
    }

    &-links {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    &-link {
      display: flex;
      align-items: center;
      padding: 0.875rem;
      color: $secondary-color;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      transition: all $transition-duration ease;
      border-radius: 0.5rem;
      width: 100%;
      text-align: left;
      font-family: $font-bodoni;

      &:hover {
        color: $primary-color;
        background-color: $hover-color;
      }

      .navbar__mobile-menu-icon {
        margin-right: 1rem;
        width: 20px;
        height: 20px;
        fill: currentColor;
      }
    }
  }
}

.cart-count {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: $primary-color;
  color: $background-color;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: $font-bodoni;

  &.animate {
    animation: bounce 0.3s ease;
  }
}

.navbar__icon {
  width: 22px;
  height: 22px;
  fill: $primary-color;
  transition: all $transition-duration ease;

  @include mobile {
    width: 20px;
    height: 20px;
  }

  &:hover {
    fill: darken($primary-color, 10%);
  }
}

.navbar__user-icon-svg,
.navbar__cart-icon-svg {
  @extend .navbar__icon;
}

.MuiSvgIcon-root {
  color: $primary-color !important;
  transition: all $transition-duration ease !important;
  
  &:hover {
    color: darken($primary-color, 10%) !important;
  }
}

.announcement-strip {
  background-color: #3a3a3a;
  color: #ffffff;
  text-align: center;
  padding: 6px 1rem;
  font-size: 14px;
  width: 85%;
  position: relative;
  top: 0;
  z-index: 1001;
  font-family: $font-bodoni;
}

.navbar--mobile {
  position: sticky;
  top: 0;
  
  .navbar__container {
    height: 70px;
  }
}