// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

.product-showcase {
  display: flex;
  background: linear-gradient(135deg, #f0fafb 0%, #cff1f3 100%);
  font-family: $font-bodoni;
  overflow: hidden;
  max-width: 2000px;
  margin: 2rem auto;
  
  overflow: hidden;
  max-width: 2000px;
  margin: 2rem auto;
  
  .content-wrapper {
    flex: 1;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-info {
    max-width: 500px;

    > * {
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 0.5s forwards;
      animation-delay: calc(var(--delay) * 0.1s);
    }
  }

  .title {
    font-size: 2.5rem;
    color: #066469;
    margin-bottom: 1rem;
    --delay: 1;
  }

  .category {
    display: flex;
    align-items: center;
    color: #6cd4d9;
    font-weight: 600;
    margin-bottom: 1rem;
    --delay: 2;

    .home-icon {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      background: currentColor;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'/%3E%3Cpolyline points='9 22 9 12 15 12 15 22'/%3E%3C/svg%3E");
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }

  .product-name {
    font-size: 3rem;
    color: #2d3748;
    margin-bottom: 1rem;
    --delay: 3;
  }

  .description {
    color: #4a5568;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    --delay: 4;
  }

  .features {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    --delay: 5;
  }

  .feature-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon-wrapper {
      background-color: #6cd4d9;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      &::after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        background-color: white;
        mask-repeat: no-repeat;
        mask-position: center;
      }

      &.droplet-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z'/%3E%3C/svg%3E");
      }

      &.leaf-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z'/%3E%3Cpath d='M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12'/%3E%3C/svg%3E");
      }

      &.clock-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpolyline points='12 6 12 12 16 14'/%3E%3C/svg%3E");
      }
    }

    span {
      font-size: 0.9rem;
      font-weight: 600;
      color: #2d3748;
    }
  }

  .shop-now {
    background-color: #066469;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    --delay: 6;

    &:hover {
      background-color: #6cd4d9;
      transform: translateY(-3px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  .image-wrapper {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    position: relative;
    overflow: hidden;
  }

  .product-image {
    max-width: 80%;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    animation: float 3s ease-in-out infinite;
    position: relative;
    z-index: 2;
  }

  .glow-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, rgba(84,197,203,0.2) 0%, rgba(84,197,203,0) 70%);
    filter: blur(20px);
    animation: glow 4s ease-in-out infinite;
  }

  .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  .bubble {
    position: absolute;
    width: 60px;
    height: 60px;
    background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4));
    border-radius: 50%;
    bottom: -60px;
    animation-name: float-and-pop;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    &.popped {
      animation: pop 0.5s forwards;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes float {
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(10px, -10px);
    }
  }

  @keyframes glow {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes float-and-pop {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      transform: translateY(-100vh);
      opacity: 1;
    }
    100% {
      transform: translateY(-100vh);
      opacity: 0;
    }
  }

  @keyframes pop {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    .content-wrapper {
      padding: 2rem;
    }

    .image-wrapper {
      padding: 2rem;
    }

    .product-image {
      max-width: 60%;
    }

    .features {
      flex-direction: column;
      align-items: center;
    }
  }
}