@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');

@keyframes pulse-glow {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.05);
    text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.8);
  }
}

.combo-banner-wrapper {
  padding-top: 2rem; // Adds 2rem padding above the banner
}

.combo-banner {
  width: 100%;
  background-color: #066469;
  overflow: hidden;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    color: white;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    white-space: nowrap;
    font-family: 'Oswald', sans-serif;
    animation: pulse-glow 2s infinite ease-in-out;
  }
}