// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

// OrderSuccess.scss
.order-success-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(135deg, #f6f9ff 0%, #f8f0ff 100%);
  font-family: $font-bodoni;

  .success-card {
    max-width: 28rem;
    width: 100%;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    font-family: $font-bodoni;
    
    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    &:hover {
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
    }
  }

  .success-content {
    padding: 2.5rem 2rem;
  }

  // Custom Animated Success Icon
  .success-icon-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto 2rem;
  }

  .success-icon {
    position: relative;
    width: 100%;
    height: 100%;

    &-circle {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 3px solid #4CAF50;
      border-top-color: transparent;
      animation: circle-draw 1.5s ease-in-out forwards;
    }

    &-check {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 25%;
      border-right: 3px solid #4CAF50;
      border-bottom: 3px solid #4CAF50;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      animation: check-draw 0.5s ease-in-out 1s forwards;
    }
  }

  // Message Section
  .message-section {
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
    font-family: $font-bodoni;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #2196F3, #9C27B0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: $font-bodoni;
  }

  .order-id-box {
    background-color: rgba(33, 150, 243, 0.1);
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(33, 150, 243, 0.2);
    box-shadow: 0 2px 8px rgba(33, 150, 243, 0.08);
    transform: translateY(0);
    transition: all 0.3s ease;
    font-family: $font-bodoni;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(33, 150, 243, 0.12);
    }

    p {
      color: #666;
      font-family: $font-bodoni;
      
      span {
        color: #2196F3;
        font-weight: 600;
        margin-left: 0.25rem;
        font-family: $font-bodoni;
      }
    }
  }

  .confirmation-text {
    color: #666;
    margin-bottom: 2rem;
    line-height: 1.6;
    font-family: $font-bodoni;
  }

  // Button Group
  .button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.6s;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  // Button Styles
  .primary-button,
  .secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.875rem 1.75rem;
    border-radius: 0.75rem;
    font-weight: 500;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0);
    font-family: $font-bodoni;

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }

    &:hover {
      transform: translateY(-2px);
      
      .button-icon {
        transform: scale(1.1);
      }
    }

    &:active {
      transform: translateY(0);
    }
  }

  .primary-button {
    background: linear-gradient(135deg, #2196F3, #1976D2);
    color: white;
    box-shadow: 0 4px 12px rgba(33, 150, 243, 0.3);
    
    &:hover {
      box-shadow: 0 6px 16px rgba(33, 150, 243, 0.4);
    }
  }

  .secondary-button {
    background: linear-gradient(135deg, rgba(156, 39, 176, 0.1), rgba(156, 39, 176, 0.2));
    color: #9C27B0;
    box-shadow: 0 4px 12px rgba(156, 39, 176, 0.1);
    
    &:hover {
      box-shadow: 0 6px 16px rgba(156, 39, 176, 0.15);
      background: linear-gradient(135deg, rgba(156, 39, 176, 0.15), rgba(156, 39, 176, 0.25));
    }
  }
}

// Keyframe Animations
@keyframes circle-draw {
  0% {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }
  50% {
    transform: rotate(360deg) scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: rotate(720deg) scale(1);
    opacity: 1;
  }
}

@keyframes check-draw {
  0% {
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) rotate(45deg) scale(1);
    opacity: 1;
  }
}