// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

.terms-container {
    min-height: 100vh;
    background-color: #f9fafb;
    padding: 3rem 1rem;
    font-family: $font-bodoni;
  
    .terms-content {
      width: 95%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 1rem;
  
      @media (max-width: 768px) {
        padding: 0 0.5rem;
        margin: 0;
      }
    }
  
    .terms-header {
      text-align: center;
      margin-bottom: 3rem;
  
      h1 {
        font-size: 2.25rem;
        font-weight: 700;
        color: #111827;
        margin-bottom: 1rem;
  
        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }
  
      p {
        color: #4b5563;
      }
    }
  
    .terms-sections {
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      margin: 0 auto;
      width: 100%;
  
      @media (max-width: 768px) {
        padding: 1rem;
        border-radius: 0.5rem;
      }
    }
  
    .terms-section {
      margin-bottom: 1.5rem;
  
      &__button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        background-color: #f9fafb;
        border-radius: 0.5rem;
        transition: background-color 0.2s ease;
        cursor: pointer;
        border: none;
  
        &:hover {
          background-color: #f3f4f6;
        }
  
        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          color: #1f2937;
  
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
  
        .toggle-icon {
          font-size: 1.5rem;
          font-weight: 700;
          color: #4b5563;
          transition: transform 0.3s ease;
  
          &--open {
            transform: rotate(180deg);
          }
        }
      }
  
      &__content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        opacity: 0;
  
        &--open {
          max-height: 500px;
          opacity: 1;
          padding: 1rem;
          margin-top: 1rem;
        }
  
        p {
          color: #4b5563;
          line-height: 1.75;
          margin-bottom: 1rem;
  
          @media (max-width: 768px) {
            font-size: 0.875rem;
            line-height: 1.6;
          }
        }
  
        ul {
          list-style-type: disc;
          padding-left: 1.5rem;
          color: #4b5563;
  
          li {
            margin-bottom: 0.75rem;
  
            @media (max-width: 768px) {
              font-size: 0.875rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  
    .terms-footer {
      text-align: center;
      margin-top: 2rem;
      color: #6b7280;
      font-size: 0.875rem;
      padding: 0 1rem;
  
      @media (max-width: 768px) {
        font-size: 0.75rem;
        margin-top: 1.5rem;
      }
    }
  }