.review-slider {
    width: 100%;
    max-width: 32rem;
    margin: 0 auto;
    
    @media (min-width: 768px) {
      max-width: 64rem;
    }
    
    &__container {
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      overflow: hidden;
    }
  
    &__content {
      position: relative;
      padding: 2rem;
    }
  
    &__reviews-wrapper {
      display: flex;
      gap: 2rem;
      
      &--desktop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }
    }
  
    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12rem;
  
      .spinner {
        width: 2rem;
        height: 2rem;
        border: 2px solid #f3f3f3;
        border-top: 2px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    }
  
    &__error {
      color: #ef4444;
      text-align: center;
      padding: 1rem;
    }
  
    &__empty {
      color: #6b7280;
      text-align: center;
      padding: 1rem;
    }
  
    &__nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;
      border-radius: 9999px;
      padding: 0.5rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      border: none;
      cursor: pointer;
      transition: background-color 0.2s ease;
      z-index: 10;
  
      &:hover {
        background-color: #f9fafb;
      }
  
      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #4b5563;
      }
  
      &--prev {
        left: 0;
        transform: translate(-1rem, -50%);
      }
  
      &--next {
        right: 0;
        transform: translate(1rem, -50%);
      }
    }
  
    &__review {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 0 2rem;
    }
  
    &__user {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  
    &__avatar {
      background-color: #f3f4f6;
      padding: 0.5rem;
      border-radius: 9999px;
  
      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #4b5563;
      }
    }
  
    &__username {
      font-weight: 500;
      color: #111827;
    }
  
    &__rating {
      display: flex;
      gap: 0.25rem;
  
      .star-filled {
        fill: #fbbf24;
        color: #fbbf24;
      }
  
      .star-empty {
        color: #d1d5db;
      }
    }
  
    &__verified {
      font-size: 0.875rem;
      color: #6b7280;
    }
  
    &__comment {
      text-align: center;
      color: #374151;
      line-height: 1.625;
      margin: 0;
    }
  
    &__image {
      margin-top: 1rem;
      
      img {
        max-height: 12rem;
        border-radius: 0.5rem;
        object-fit: cover;
      }
    }
  
    &__date {
      font-size: 0.875rem;
      color: #6b7280;
    }
  
    &__dots {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      padding: 1.5rem;
    }
  
    &__dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 9999px;
      background-color: #d1d5db;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: background-color 0.2s ease;
  
      &--active {
        background-color: #2563eb;
      }
    }
  
    &__view-all {
      display: block;
      margin: 2rem auto 0;
      padding: 0.75rem 1.5rem;
      background-color: #2563eb;
      color: white;
      border: none;
      border-radius: 9999px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: #1d4ed8;
      }
    }
  
    &__modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 50;
      padding: 1rem;
  
      &-content {
        background-color: white;
        border-radius: 1rem;
        padding: 2rem;
        max-width: 64rem;
        width: 100%;
        max-height: 90vh;
        overflow-y: auto;
        position: relative;
  
        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #111827;
          margin-bottom: 2rem;
        }
      }
  
      &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.5rem;
  
        svg {
          width: 1.5rem;
          height: 1.5rem;
          color: #6b7280;
        }
  
        &:hover svg {
          color: #374151;
        }
      }
  
      &-reviews {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      }
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }