@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

$primary-teal: #066469;
$secondary-teal: #0d6366;
$light-teal: #73c8cc;
$dark-gray: #333333;
$light-gray: #f5f5f5;
$white: #ffffff;
$border-color: #e0e0e0;
$error-red: #ef4444;
$success-green: #22c55e;

@mixin transition($property: all, $duration: 0.3s, $timing: ease) {
  transition: $property $duration $timing;
}

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (max-width: 320px) {
    @content;
  }
}

.cart-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
  backdrop-filter: blur(2px);
}

.cart-sidebar {
  font-family: 'Libre Bodoni', sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  background-color: $white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1003;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include mobile {
    max-width: 100%;
    font-size: 14px;
  }

  @include small-mobile {
    font-size: 13px;
  }

  &.open {
    transform: translateX(0);
  }

  .cart-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $primary-teal;
    color: $white;
    min-height: 60px;

    @include mobile {
      padding: 0.75rem;
      min-height: 50px;
    }

    @include small-mobile {
      padding: 0.5rem;
      min-height: 45px;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;

      @include mobile {
        font-size: 1.25rem;
      }

      @include small-mobile {
        font-size: 1.125rem;
      }
    }

    .close-btn {
      background: none;
      border: none;
      color: $white;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0.5rem;
      margin: -0.5rem;
      
      @include mobile {
        font-size: 1.25rem;
      }
    }
  }

  .cart-sidebar-items {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    -webkit-overflow-scrolling: touch;

    @include mobile {
      padding: 0.75rem;
    }

    @include small-mobile {
      padding: 0.5rem;
    }

    .empty-cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 200px;
      color: $dark-gray;
      text-align: center;
      padding: 2rem;

      .cart-icon {
        font-size: 3rem;
        margin-bottom: 1rem;

        @include mobile {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 1.125rem;
        margin: 0;

        @include mobile {
          font-size: 1rem;
        }
      }
    }

    .cart-item {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-bottom: 1px solid $border-color;
      gap: 0.75rem;

      @include mobile {
        padding: 0.5rem;
        gap: 0.5rem;
      }

      .item-image {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
        background-color: $light-gray;

        @include mobile {
          width: 50px;
          height: 50px;
        }

        @include small-mobile {
          width: 45px;
          height: 45px;
        }
      }

      .item-details {
        flex: 1;
        min-width: 0;

        h3 {
          font-size: 0.875rem;
          font-weight: 500;
          margin: 0 0 0.25rem;
          color: $dark-gray;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include mobile {
            font-size: 0.8125rem;
          }
        }

        .variation-name {
          font-size: 0.75rem;
          color: $dark-gray;
          margin-bottom: 0.25rem;

          @include mobile {
            font-size: 0.6875rem;
          }
        }

        .item-price {
          font-weight: 600;
          color: $primary-teal;
          margin: 0.25rem 0;
        }

        .quantity-control {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-top: 0.25rem;

          button {
            width: 24px;
            height: 24px;
            border: none;
            background-color: $light-gray;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @include mobile {
              width: 22px;
              height: 22px;
            }

            @include small-mobile {
              width: 20px;
              height: 20px;
            }

            &:hover {
              background-color: darken($light-gray, 5%);
            }
          }

          span {
            min-width: 20px;
            text-align: center;
            font-weight: 500;
          }
        }
      }

      .remove-btn {
        background: none;
        border: none;
        color: $dark-gray;
        cursor: pointer;
        padding: 0.5rem;
        margin: -0.5rem;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .cart-sidebar-coupons {
    padding: 1rem;
    border-top: 1px solid $border-color;
  
    @include mobile {
      padding: 0.75rem;
    }
  
    @include small-mobile {
      padding: 0.5rem;
    }
  
    .coupon-section-toggle {
      width: 100%;
      padding: 0.75rem;
      background: none;
      border: none;
      cursor: pointer;
      text-align: left;
      border-radius: 4px;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: rgba($light-teal, 0.1);
      }
  
      .applied-tag {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        background-color: $light-teal;
        color: $primary-teal;
        border-radius: 12px;
        margin-left: 0.5rem;
      }
  
      .toggle-icon {
        font-size: 1.5rem;
        font-weight: 500;
        transition: transform 0.3s ease;
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
  
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  
    .coupon-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      opacity: 0;
      
      &.open {
        max-height: 500px; // Adjust based on your content
        opacity: 1;
        transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
      }
  
      .available-coupons {
        margin-top: 1rem;
        
        h3 {
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
          color: $dark-gray;
        }
      }
  
      .coupon-grid {
        display: grid;
        gap: 0.5rem;
        margin-bottom: 1rem;
      }
  
      .coupon-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.75rem;
        background-color: $light-gray;
        border: 1px solid $border-color;
        border-radius: 4px;
        cursor: pointer;
  
        @include mobile {
          padding: 0.625rem;
        }
  
        &.active {
          background-color: rgba($light-teal, 0.2);
          border-color: $primary-teal;
        }
  
        .coupon-code {
          font-weight: 500;
          font-size: 0.875rem;
        }
  
        .coupon-discount {
          font-size: 0.75rem;
          color: $secondary-teal;
        }
      }
    }
  
    .custom-coupon-section {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid $border-color;
  
      h3 {
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        color: $dark-gray;
      }
  
      .coupon-form {
        display: flex;
        gap: 0.5rem;
  
        @include mobile {
          gap: 0.375rem;
        }
  
        .coupon-input {
          flex: 1;
          padding: 0.625rem;
          border: 1px solid $border-color;
          border-radius: 4px;
          font-size: 0.875rem;
  
          @include mobile {
            padding: 0.5rem;
            font-size: 0.8125rem;
          }
        }
  
        .apply-btn {
          padding: 0.625rem 1rem;
          background-color: $primary-teal;
          color: $white;
          border: none;
          border-radius: 4px;
          font-size: 0.875rem;
          cursor: pointer;
          white-space: nowrap;
  
          @include mobile {
            padding: 0.5rem 0.75rem;
          }
  
          &:hover {
            background-color: darken($primary-teal, 5%);
          }
        }
      }
    }
  
    .applied-coupon {
      margin-top: 1rem;
      padding: 0.75rem;
      background-color: rgba($success-green, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      @include mobile {
        padding: 0.625rem;
        margin-top: 0.75rem;
      }
  
      .coupon-info {
        display: flex;
        align-items: center;
        gap: 0.75rem;
  
        @include mobile {
          gap: 0.5rem;
        }
  
        .coupon-label {
          font-weight: 500;
          font-size: 0.875rem;
        }
  
        .discount-amount {
          font-size: 0.75rem;
          color: $success-green;
        }
      }
  
      .remove-coupon-btn {
        background: none;
        border: none;
        color: $error-red;
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        opacity: 0.7;
  
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .cart-sidebar-footer {
    padding: 1rem;
    background-color: $light-teal;
    border-top: 1px solid $border-color;

    @include mobile {
      padding: 0.75rem;
    }

    @include small-mobile {
      padding: 0.625rem;
    }

    .subtotal,
    .discount,
    .total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      @include mobile {
        margin-bottom: 0.375rem;
      }
    }

    .discount {
      color: $success-green;
    }

    .total {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0.5rem 0;

      @include mobile {
        font-size: 1rem;
      }
    }

    .checkout-btn {
      width: 100%;
      padding: 0.875rem;
      background-color: $primary-teal;
      color: $white;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease;

      @include mobile {
        padding: 0.75rem;
        font-size: 0.9375rem;
      }

      &:hover:not(:disabled) {
        background-color: darken($primary-teal, 5%);
      }

      &:disabled {
        background-color: $light-gray;
        cursor: not-allowed;
      }
    }
  }
}

// Animation keyframes
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Helper classes
.cart-open {
  overflow: hidden;
  
  .cart-sidebar-overlay {
    animation: fadeIn 0.3s ease;
  }
  
  .cart-sidebar {
    animation: slideIn 0.3s ease;
  }
}