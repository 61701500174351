// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

.tracking-page {
    min-height: 100vh;
    background: #f9fafb;
    padding: 2rem;
    font-family: $font-bodoni;
  
    .tracking-container {
      max-width: 1200px;
      margin: 0 auto;
      background: white;
      border-radius: 16px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      overflow: hidden;
  
      .tracking-header {
        background: linear-gradient(to right, #f8fafc, #f1f5f9);
        padding: 2rem;
        border-bottom: 1px solid #e2e8f0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
  
        .order-info {
          h1 {
            font-size: 1.875rem;
            font-weight: 700;
            color: #1e293b;
            margin-bottom: 0.5rem;
            font-family: $font-bodoni;
          }
  
          .order-meta {
            display: flex;
            gap: 1.5rem;
            color: #64748b;
            font-size: 0.875rem;
            font-family: $font-bodoni;
  
            .order-id {
              font-weight: 600;
              font-family: $font-bodoni;
            }
          }
        }
  
        .estimated-delivery {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem;
          background: white;
          border-radius: 12px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
          .delivery-icon {
            width: 40px;
            height: 40px;
            background: #eff6ff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
  
            svg {
              width: 24px;
              height: 24px;
              stroke: #3b82f6;
              stroke-width: 2;
            }
          }
  
          .delivery-info {
            display: flex;
            flex-direction: column;
  
            .label {
              font-size: 0.875rem;
              color: #64748b;
              font-family: $font-bodoni;
            }
  
            .date {
              font-weight: 600;
              color: #1e293b;
              font-family: $font-bodoni;
            }
          }
        }
      }
  
      .tracking-content {
        display: grid;
        grid-template-columns: 1fr 350px;
        gap: 2rem;
        padding: 2rem;
  
        .tracking-timeline {
          .timeline-header {
            margin-bottom: 2rem;
  
            h2 {
              font-size: 1.25rem;
              font-weight: 600;
              color: #1e293b;
              font-family: $font-bodoni;
            }
          }
  
          .timeline-steps {
            position: relative;
  
            .timeline-step {
              display: flex;
              gap: 1.5rem;
              padding-bottom: 2rem;
              position: relative;
  
              .step-marker {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
  
                .marker-icon {
                  width: 32px;
                  height: 32px;
                  background: #f1f5f9;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                  transition: all 0.3s ease;
  
                  .check-icon {
                    width: 16px;
                    height: 16px;
                    stroke: #22c55e;
                    stroke-width: 2;
                    fill: none;
                  }
  
                  .marker-dot {
                    width: 12px;
                    height: 12px;
                    background: #94a3b8;
                    border-radius: 50%;
                  }
                }
  
                .marker-line {
                  position: absolute;
                  top: 32px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 2px;
                  height: calc(100% - 16px);
                  background: #e2e8f0;
                  z-index: 0;
                }
              }
  
              .step-content {
                flex: 1;
                padding-top: 4px;
  
                h3 {
                  font-weight: 600;
                  color: #1e293b;
                  margin-bottom: 0.25rem;
                  font-family: $font-bodoni;
                }
  
                p {
                  color: #64748b;
                  font-size: 0.875rem;
                  margin-bottom: 0.5rem;
                  font-family: $font-bodoni;
                }
  
                .timestamp {
                  font-size: 0.75rem;
                  color: #94a3b8;
                  font-family: $font-bodoni;
                }
  
                .current-location {
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                  margin-top: 0.5rem;
                  padding: 0.5rem;
                  background: #f8fafc;
                  border-radius: 8px;
                  font-size: 0.875rem;
                  color: #0f172a;
                  font-family: $font-bodoni;
  
                  svg {
                    width: 16px;
                    height: 16px;
                    stroke: #3b82f6;
                  }
                }
              }
  
              &.completed {
                .marker-icon {
                  background: #dcfce7;
                }
  
                .marker-line {
                  background: #22c55e;
                }
              }
  
              &.current {
                .marker-icon {
                  background: #3b82f6;
                  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  
                  .marker-dot {
                    background: white;
                  }
                }
              }
            }
          }
        }
  
        .tracking-details {
          .order-items {
            margin-bottom: 2rem;
  
            h2 {
              font-size: 1.25rem;
              font-weight: 600;
              color: #1e293b;
              margin-bottom: 1rem;
              font-family: $font-bodoni;
            }
  
            .items-list {
              background: #f8fafc;
              border-radius: 12px;
              overflow: hidden;
  
              .item-card {
                display: flex;
                gap: 1rem;
                padding: 1rem;
                border-bottom: 1px solid #e2e8f0;
  
                &:last-child {
                  border-bottom: none;
                }
  
                .item-image {
                  width: 80px;
                  height: 80px;
                  border-radius: 8px;
                  object-fit: cover;
                }
  
                .item-info {
                  flex: 1;
  
                  h3 {
                    font-weight: 500;
                    color: #1e293b;
                    margin-bottom: 0.5rem;
                    font-family: $font-bodoni;
                  }
  
                  .item-meta {
                    display: flex;
                    justify-content: space-between;
                    color: #64748b;
                    font-size: 0.875rem;
                    font-family: $font-bodoni;
  
                    .quantity {
                      background: #f1f5f9;
                      padding: 0.25rem 0.75rem;
                      border-radius: 9999px;
                      font-family: $font-bodoni;
                    }
  
                    .price {
                      font-weight: 600;
                      color: #1e293b;
                      font-family: $font-bodoni;
                    }
                  }
                }
              }
            }
          }
  
          .shipping-info {
            h2 {
              font-size: 1.25rem;
              font-weight: 600;
              color: #1e293b;
              margin-bottom: 1rem;
              font-family: $font-bodoni;
            }
  
            .address-card {
              background: #f8fafc;
              border-radius: 12px;
              padding: 1.5rem;
              display: flex;
              gap: 1rem;
  
              .address-icon {
                width: 40px;
                height: 40px;
                background: #eff6ff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
  
                svg {
                  width: 24px;
                  height: 24px;
                  stroke: #3b82f6;
                  stroke-width: 2;
                }
              }
  
              .address-details {
                h3 {
                  font-weight: 600;
                  color: #1e293b;
                  margin-bottom: 0.5rem;
                  font-family: $font-bodoni;
                }
  
                p {
                  color: #64748b;
                  font-size: 0.875rem;
                  line-height: 1.5;
                  margin-bottom: 0.25rem;
                  font-family: $font-bodoni;
  
                  &.phone {
                    margin-top: 0.5rem;
                    color: #3b82f6;
                    font-weight: 500;
                    font-family: $font-bodoni;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Responsive Styles
  @media (max-width: 1024px) {
    .tracking-page {
      padding: 1rem;
  
      .tracking-container {
        .tracking-header {
          flex-direction: column;
          gap: 1.5rem;
  
          .estimated-delivery {
            width: 100%;
          }
        }
  
        .tracking-content {
          grid-template-columns: 1fr;
          
          .tracking-details {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 1.5rem;
          }
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    .tracking-page {
      padding: 0;
  
      .tracking-container {
        border-radius: 0;
        box-shadow: none;
  
        .tracking-header {
          padding: 1.5rem;
  
          .order-meta {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
  
        .tracking-content {
          padding: 1.5rem;
  
          .timeline-steps {
            .timeline-step {
              gap: 1rem;
  
              .step-content {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
  
          .tracking-details {
            grid-template-columns: 1fr;
  
            .items-list {
              .item-card {
                .item-image {
                  width: 60px;
                  height: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Animations
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes progressLine {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  
  .tracking-container {
    animation: fadeIn 0.3s ease-out;
  }
  
  .timeline-step {
    animation: fadeIn 0.3s ease-out;
  
    .marker-line {
      transform-origin: top;
      animation: progressLine 0.6s ease-out forwards;
    }
  }
  
  // Custom Scrollbar
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  
    &:hover {
      background: #94a3b8;
    }
  }
  
  // Print Styles
  @media print {
    .tracking-page {
      padding: 0;
      background: white;
  
      .tracking-container {
        box-shadow: none;
        border-radius: 0;
  
        .tracking-header {
          background: white;
        }
  
        .tracking-content {
          grid-template-columns: 1fr;
  
          .tracking-timeline {
            .timeline-step {
              break-inside: avoid;
            }
          }
        }
      }
    }
  }