.checkout-page {
  background-color: #fff;
  min-height: 100vh;
  
  .checkout-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 14px 18px;

    @media (max-width: 768px) {
      padding: 10px;
    }
  }

  .amazon-logo {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
    
    img {
      height: 32px;
      
      @media (max-width: 768px) {
        height: 24px;
      }
    }

    .checkout-text {
      font-size: 24px;
      color: #111;
      margin-left: 8px;
      
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    .lock-icon {
      margin-left: auto;
    }
  }

  .checkout-grid {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .payment-methods {
    .payment-options {
      @media (max-width: 768px) {
        margin: 0 -10px;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        color: #111;
        margin-bottom: 16px;
      }
  
      .payment-option {
        margin-bottom: 16px;
        border: 1px solid #d5d9d9;
        border-radius: 8px;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        padding: 16px;
        position: relative;
  
        @media (max-width: 768px) {
          margin: 0 10px 16px;
          padding: 12px;
          
          label {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }
          
          .payment-price-info {
            text-align: left;
            border-left: none;
            border-top: 1px solid #e6e6e6;
            padding: 12px 0 0;
            margin: 12px 0 0;
            width: 100%;
          }
        }
  
        &:hover {
          border-color: #888;
        }
  
        &:has(input:checked) {
          border-color: #0066c0;
          background-color: #f0f8ff;
        }
  
        input[type="radio"] {
          margin-right: 16px;
          width: 18px;
          height: 18px;
          flex-shrink: 0;
        }
  
        label {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          gap: 20px;
          min-height: 50px;
        }
  
        .payment-method-content {
          flex: 1;
  
          .payment-method-title {
            font-size: 14px;
            color: #0F1111;
            font-weight: 500;
            margin-bottom: 6px;
          }
  
          .card-icons {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 8px;
            
            @media (max-width: 768px) {
              flex-wrap: wrap;
              gap: 6px;
            }
            
            img {
              height: 24px;
              object-fit: contain;

              @media (max-width: 768px) {
                height: 20px;
              }
            }
          }
  
          .unavailable-text {
            color: #CC1F1F;
            font-size: 12px;
            margin-top: 4px;
            display: block;
          }
        }
  
        .payment-price-info {
          text-align: right;
          min-width: 140px;
          margin-left: auto;
          padding-left: 16px;
          border-left: 1px solid #e6e6e6;
  
          .final-price {
            font-size: 18px;
            font-weight: 700;
            color: #0F1111;
            display: block;
            margin-bottom: 4px;
          }
  
          .price-tag {
            font-size: 13px;
            color: #067D62;
            background: #E7F4F1;
            padding: 2px 8px;
            border-radius: 4px;
            display: inline-block;
            margin-bottom: 4px;
  
            &.extra-charge {
              color: #666;
              background: #F0F2F2;
            }
          }
  
          .savings-note {
            font-size: 12px;
            color: #067D62;
            display: block;
          }
        }
  
        &:has(input:disabled) {
          opacity: 0.7;
          cursor: not-allowed;
  
          label {
            cursor: not-allowed;
          }
  
          .payment-price-info {
            opacity: 0.7;
          }
        }
      }
    }
  
    .use-payment-button {
      width: 100%;
      padding: 12px;
      background: #FFD814;
      border: 1px solid #FCD200;
      border-radius: 8px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      margin-top: 24px;
      transition: all 0.2s ease;
  
      &:hover {
        background: #F7CA00;
      }
  
      &:disabled {
        background: #F9FAFB;
        border-color: #D5D9D9;
        cursor: not-allowed;
        opacity: 0.7;
      }

      @media (max-width: 768px) {
        margin-top: 16px;
        padding: 14px;
      }
    }
  }

  .checkout-section {
    margin-bottom: 20px;
    
    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
    
    .section-header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;

      .section-number {
        font-weight: 700;
        color: #565959;
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        color: #111;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      .change-button {
        margin-left: auto;
        color: #007185;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        font-size: 14px;

        &:hover {
          color: #C7511F;
          text-decoration: underline;
        }
      }
    }

    .section-content {
      padding: 0 40px;
      
      @media (max-width: 768px) {
        padding: 0 16px;
      }
    }
  }

  .address-details {
    font-size: 14px;
    line-height: 1.4;
    color: #111;

    .address-type {
      display: inline-block;
      padding: 2px 8px;
      background: #F0F2F2;
      border-radius: 4px;
      margin-bottom: 8px;
      font-size: 12px;
      color: #666;
    }

    p {
      margin: 4px 0;
      
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
  }

  .order-items {
    .order-item {
      display: flex;
      gap: 16px;
      margin-bottom: 20px;
      
      @media (max-width: 768px) {
        gap: 12px;
        margin-bottom: 16px;
      }

      .product-image {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;

        @media (max-width: 768px) {
          width: 60px;
          height: 60px;
        }
      }

      .item-details {
        flex: 1;

        h3 {
          font-size: 14px;
          margin: 0 0 4px;
          color: #0F1111;

          @media (max-width: 768px) {
            font-size: 13px;
          }
        }

        p {
          margin: 2px 0;
          font-size: 14px;
          color: #565959;

          @media (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .order-summary {
    background: #F0F2F2;
    border-radius: 8px;
    padding: 20px;

    @media (max-width: 768px) {
      position: sticky;
      bottom: 0;
      margin: 0 -10px;
      border-radius: 8px 8px 0 0;
      box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
      padding: 16px;
      
      .summary-details {
        display: none;
      }
    }

    .place-order-button {
      width: 100%;
      padding: 14px;
      background: #FFD814;
      border: 1px solid #FCD200;
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 12px;

      &:hover {
        background: #F7CA00;
      }

      &:disabled {
        background: #F9FAFB;
        border-color: #D5D9D9;
        cursor: not-allowed;
        opacity: 0.7;
      }

      @media (max-width: 768px) {
        margin: 0;
        border-radius: 0;
        padding: 16px;
      }
    }

    .order-note {
      font-size: 12px;
      color: #565959;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin: 12px 16px;
        text-align: center;
      }
    }

    .summary-details {
      h2 {
        font-size: 16px;
        color: #111;
        margin-bottom: 12px;
      }

      .summary-row {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #111;
        margin-bottom: 8px;

        &.order-total {
          font-weight: 700;
          border-top: 1px solid #DDD;
          padding-top: 8px;
          margin-top: 8px;
        }

        &.discount {
          color: #067D62;
        }
      }

      .delivery-info {
        display: block;
        color: #007185;
        font-size: 12px;
        margin-top: 12px;
        text-decoration: none;

        &:hover {
          color: #C7511F;
          text-decoration: underline;
        }
      }
    }
  }

  // Enhanced form responsiveness
  .address-form {
    @media (max-width: 768px) {
      .form-row {
        flex-direction: column;
        gap: 12px;
        
        .form-group {
          width: 100%;
        }
      }
    }
  }

  // Fix input sizing on mobile
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    @media (max-width: 768px) {
      font-size: 16px;
      padding: 8px;
    }
  }
}