/* Import Libre Baskerville font */
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;700&display=swap');

/* Font variables */
:root {
  --font-primary: 'Libre Bodoni', serif;
  --font-secondary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.caution-notice {
    margin: 0 auto;
    max-width: 1200px;
    padding: 15px 20px;
    margin-bottom: 2rem;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-family: var(--font-secondary);
}
  
.caution-notice__icon {
    color: #856404;
    flex-shrink: 0;
    margin-top: 2px;
}
  
.caution-notice__text {
    color: #856404;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-family: var(--font-primary);
}