.image-slider {
  &-wrapper {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background-color: #111827;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      height: 400px;
    }
  }

  &-container {
    width: 100%;
    height: 100%;
    position: relative;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(135deg, #f3f4f6 25%, #e5e7eb 25%, #e5e7eb 50%, #f3f4f6 50%, #f3f4f6 75%, #e5e7eb 75%);
      background-size: 20px 20px;
      animation: slideBackground 30s linear infinite;

      p {
        font-size: 1.125rem;
        color: #4b5563;
        text-align: center;
        animation: pulse 2s ease-in-out infinite;
      }
    }
  }

  &-slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);

    &.active {
      opacity: 1;
      transform: scale(1);
      z-index: 10;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: center;

      &.active {
        animation: kenBurns 20s ease-out infinite alternate;
      }
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2) 0%,
        transparent 40%,
        transparent 60%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }

  &-controls {
    position: absolute;
    inset: 0;
    z-index: 20;
    opacity: 0;
    transform: translateY(8px);
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
      opacity: 1;
      transform: translateY(0);
    }

    .image-slider-wrapper:hover & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    button {
      width: 48px;
      height: 48px;
      border-radius: 9999px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(8px);
      border: 1px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: scale(1.1);
      }

      svg {
        width: 24px;
        height: 24px;
        color: white;
      }
    }
  }

  &-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    border-radius: 20px;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      background: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }

      &.active {
        width: 24px;
        background: white;
        border-radius: 4px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          inset: -4px;
          border-radius: inherit;
          border: 2px solid rgba(255, 255, 255, 0.3);
          animation: ripple 1.5s ease-out infinite;
        }
      }
    }
  }

  &-counter {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 6px 12px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    color: white;
    font-size: 14px;
    font-weight: 500;
  }

  &-playpause {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.1);
      background: rgba(0, 0, 0, 0.5);
    }

    svg {
      width: 20px;
      height: 20px;
      color: white;
    }
  }

  
}

@keyframes kenBurns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes slideBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}