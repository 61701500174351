.invoice-container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 1rem;
  
    @media (max-width: 768px) {
      padding: 0.5rem;
    }
  
    .invoice-card {
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
  
      @media (max-width: 768px) {
        padding: 1rem;
      }
  
      .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee;
        flex-wrap: wrap;
        gap: 1rem;
  
        @media (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
        }
  
        h1 {
          font-size: 1.5rem;
          font-weight: 700;
          color: #1a1a1a;
  
          @media (max-width: 480px) {
            font-size: 1.25rem;
          }
        }
  
        .download-btn {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: #3b82f6;
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 0.375rem;
          transition: background-color 0.2s;
          white-space: nowrap;
  
          &:hover {
            background-color: #2563eb;
          }
  
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
  
      .invoice-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
        margin-bottom: 2rem;
  
        @media (max-width: 640px) {
          grid-template-columns: 1fr;
          gap: 1rem;
        }
  
        .address-block {
          h3 {
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: #374151;
          }
  
          p {
            margin-bottom: 0.25rem;
            color: #4b5563;
            word-break: break-word;
          }
        }
      }
  
      .order-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 1.5rem;
        padding: 1rem;
        background-color: #f9fafb;
        border-radius: 0.375rem;
  
        @media (max-width: 480px) {
          grid-template-columns: 1fr;
          gap: 0.5rem;
        }
  
        .info-item {
          .label {
            font-size: 0.875rem;
            color: #6b7280;
            margin-bottom: 0.25rem;
          }
  
          .value {
            font-weight: 500;
            color: #1f2937;
          }
        }
      }
  
      .invoice-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        margin-top: 1rem;
        overflow-x: auto;
        display: block;
  
        @media (max-width: 768px) {
          font-size: 0.875rem;
        }
  
        // Make table scrollable horizontally on mobile
        @media (max-width: 640px) {
          display: block;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          
          // Optional: Add visual indication of scrolling
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 5px;
            background: linear-gradient(to left, rgba(0,0,0,0.05), transparent);
          }
        }
  
        th {
          background-color: #f3f4f6;
          padding: 0.75rem;
          text-align: left;
          font-weight: 600;
          color: #374151;
          border-bottom: 2px solid #e5e7eb;
          white-space: nowrap;
  
          @media (max-width: 768px) {
            padding: 0.5rem;
          }
  
          &:last-child {
            text-align: right;
          }
        }
  
        td {
          padding: 0.75rem;
          border-bottom: 1px solid #e5e7eb;
          color: #4b5563;
  
          @media (max-width: 768px) {
            padding: 0.5rem;
          }
  
          &:last-child {
            text-align: right;
          }
        }
  
        tfoot {
          td {
            font-weight: 600;
            color: #1f2937;
            border-top: 2px solid #e5e7eb;
          }
        }
      }
    }
  }
  
  // Loading and error states
  .loading-state,
  .error-state {
    text-align: center;
    padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 400px;
  
    @media (max-width: 480px) {
      margin: 1rem auto;
      padding: 1rem;
    }
  }
  
  .error-state {
    color: #ef4444;
    border: 1px solid #fee2e2;
  }