// src/styles/PaymentCheckout.scss
.payment-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  
    .payment-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
  
      @media (min-width: 768px) {
        grid-template-columns: 1.5fr 1fr;
      }
    }
  
    .payment-section {
      .payment-header {
        margin-bottom: 2rem;
  
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #111827;
          margin-bottom: 1rem;
        }
  
        .checkout-steps {
          display: flex;
          align-items: center;
          gap: 1rem;
  
          .step {
            color: #6b7280;
            font-size: 0.875rem;
  
            &.active {
              color: #2563eb;
              font-weight: 500;
            }
  
            &.completed {
              color: #059669;
            }
  
            &:not(:last-child)::after {
              content: ">";
              margin-left: 1rem;
              color: #9ca3af;
            }
          }
        }
      }
  
      .payment-methods {
        margin-bottom: 2rem;
  
        .payment-method {
          display: block;
          border: 1px solid #e5e7eb;
          border-radius: 0.5rem;
          padding: 1rem;
          margin-bottom: 1rem;
          cursor: pointer;
          transition: all 0.2s;
  
          &:hover {
            border-color: #2563eb;
          }
  
          &.selected {
            border-color: #2563eb;
            background-color: #f0f7ff;
          }
  
          .method-content {
            display: flex;
            align-items: center;
            gap: 1rem;
  
            .method-icon {
              font-size: 1.5rem;
              width: 48px;
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f3f4f6;
              border-radius: 50%;
            }
  
            .method-details {
              h3 {
                font-weight: 500;
                margin-bottom: 0.25rem;
              }
  
              p {
                color: #6b7280;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
  
      .card-details, .upi-details {
        background: #f9fafb;
        border-radius: 0.5rem;
        padding: 1.5rem;
        margin-bottom: 2rem;
  
        .form-group {
          margin-bottom: 1rem;
  
          label {
            display: block;
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
          }
  
          input {
            width: 100%;
            padding: 0.75rem;
            border: 1px solid #d1d5db;
            border-radius: 0.375rem;
            
            &:focus {
              outline: none;
              border-color: #2563eb;
              box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
            }
          }
        }
  
        .form-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
        }
      }
    }
  
    .order-summary {
      background: white;
      border-radius: 0.5rem;
      padding: 1.5rem;
      border: 1px solid #e5e7eb;
      height: fit-content;
  
      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
  
      .delivery-address {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #e5e7eb;
  
        h3 {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }
  
        .address-details {
          font-size: 0.875rem;
          line-height: 1.5;
          color: #4b5563;
        }
      }
  
      .summary-items {
        margin-bottom: 1.5rem;
  
        .summary-item {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
  
          img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 0.375rem;
          }
  
          .item-details {
            flex: 1;
  
            h4 {
              font-weight: 500;
              margin-bottom: 0.25rem;
            }
  
            p {
              color: #6b7280;
              font-size: 0.875rem;
  
              &.price {
                color: #111827;
                font-weight: 500;
              }
            }
          }
        }
      }
  
      .summary-totals {
        .summary-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          font-size: 0.875rem;
          color: #4b5563;
  
          &.total {
            font-size: 1rem;
            font-weight: 600;
            color: #111827;
            margin-top: 0.5rem;
            padding-top: 0.5rem;
            border-top: 1px solid #e5e7eb;
          }
        }
      }
    }
  
    .payment-button {
      width: 100%;
      padding: 1rem;
      background: #2563eb;
      color: white;
      border: none;
      border-radius: 0.5rem;
      font-weight: 500;
      cursor: pointer;
      margin-top: 1.5rem;
  
      &:hover {
        background: #1d4ed8;
      }
  
      &:disabled {
        background: #9ca3af;
        cursor: not-allowed;
      }
    }
  }