// Import Libre Baskerville font
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;700&display=swap');

$primary-color: #066469;
$primary-dark: darken($primary-color, 5%);
$success-color: #10b981;
$warning-color: #f59e0b;
$danger-color: #ef4444;

// Font variables
$font-primary: 'Libre Baskerville', serif;
$font-secondary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

.bestseller-showcase {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.bestseller {
  &__title {
    font-family: $font-primary;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    color: #1a1f36;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    padding: 1rem;

    @media (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      gap: 1rem;
      padding-bottom: 1rem;
      
      /* Hide scrollbar */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &__card {
    background: #fff;
    border-radius: 16px;
    border: 1px solid rgba(#e2e8f0, 0.6);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      flex: 0 0 280px;
      scroll-snap-align: start;
    }

    &:hover {
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-4px);
    }
  }

  &__header {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__badge {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    gap: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: $font-secondary;

    &--in-stock {
      background-color: rgba($success-color, 0.9);
      color: white;
    }

    &--low-stock {
      background-color: rgba($warning-color, 0.9);
      color: white;
    }

    &--out-stock {
      background-color: rgba($danger-color, 0.9);
      color: white;
    }
  }

  &__discount {
    background: linear-gradient(135deg, $success-color, darken($success-color, 10%));
    color: white;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    font-family: $font-secondary;
  }

  &__image-container {
    position: relative;
    padding-top: 100%;
    background: #f8fafc;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      padding: 1rem;
    }

    &:hover img {
      transform: scale(1.02);
    }
  }

  &__wishlist {
    position: absolute;
    top: 12px;
    right: 12px;
    background: white;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
      background: #f8fafc;
    }
  }

  &__content {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #1a1f36;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
  }

  &__details {
    background: #f8fafc;
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  &__volume {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__label {
    color: #718096;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: $font-secondary;
  }

  &__value {
    color: #1a1f36;
    font-size: 0.813rem;
    font-weight: 500;
    font-family: $font-secondary;
  }

  &__price-block {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__current-price {
    font-family: $font-primary;
    font-size: 1.25rem;
    font-weight: 700;
    color: #1a1f36;
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
  }

  &__currency {
    font-size: 0.875rem;
    font-family: $font-secondary;
  }

  &__original-price {
    color: #718096;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    font-family: $font-secondary;
  }

  &__mrp {
    text-decoration: line-through;
  }

  &__tax-info {
    font-size: 0.7rem;
    margin-top: 2px;
    font-family: $font-secondary;
  }

  &__shipping {
    font-size: 0.75rem;
    color: $success-color;
    font-weight: 600;
    font-family: $font-secondary;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    padding: 0.75rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    font-family: $font-secondary;

    &--secondary {
      background: white;
      border: 1.5px solid $primary-color;
      color: $primary-color;

      &:hover:not(:disabled) {
        background: rgba($primary-color, 0.1);
      }
    }

    &--primary {
      background: linear-gradient(135deg, $primary-color, $primary-dark);
      color: white;

      &:hover:not(:disabled) {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba($primary-color, 0.2);
      }
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background: #718096;
    }
  }
}

@media (max-width: 768px) {
  .bestseller {
    &__image-container {
      height: 200px;
      padding-top: 0;
    }
  }
}

@media (max-width: 480px) {
  .bestseller {
    &__image-container {
      height: 300px;
    }

    &__actions {
      grid-template-columns: 1fr;
    }

    &__content {
      padding: 0.75rem;
    }

    &__name {
      font-size: 0.938rem;
    }
  }
}