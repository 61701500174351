// Variables
$primary-color: #2563eb;
$primary-hover: #1d4ed8;
$text-color: #111827;
$text-gray: #4b5563;
$white: #ffffff;
$shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$container-width: 72rem; // 1152px

// Mixins
@mixin card {
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  padding: 2rem;
}

@mixin section-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: $text-color;
}

// Main Container
.about-container {
  max-width: $container-width;
  margin: 0 auto;
  padding: 3rem 1rem;
}

// Hero Section
.hero-section {
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $text-color;
  }

  p {
    font-size: 1.125rem;
    color: $text-gray;
    max-width: 42rem;
    margin: 0 auto;
  }
}

// Mission Section
.mission-section {
  @include card;
  margin-bottom: 4rem;

  h2 {
    @include section-title;
  }

  .mission-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .mission-item {
    text-align: center;

    .icon {
      width: 3rem;
      height: 3rem;
      margin: 0 auto 1rem;
      color: $primary-color;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $text-color;
    }

    p {
      color: $text-gray;
    }
  }
}

// Info Grid
.info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .contact-section, .shipping-section {
    @include card;

    h2 {
      @include section-title;
    }
  }
}

// Contact Section
.contact-section {
  .contact-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .contact-item {
    display: flex;
    align-items: flex-start;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
      color: $primary-color;
      flex-shrink: 0;
    }

    .contact-content {
      h3 {
        font-weight: 600;
        color: $text-color;
        margin-bottom: 0.25rem;
      }

      p {
        color: $text-gray;
      }
    }
  }
}

// Shipping Section
.shipping-section {
  .shipping-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .shipping-item {
    h3 {
      font-weight: 600;
      color: $text-color;
      margin-bottom: 0.5rem;
    }

    p {
      color: $text-gray;
    }
  }
}

// CTA Section
.cta-section {
  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $text-color;
  }

  p {
    color: $text-gray;
    margin-bottom: 1.5rem;
  }

  .cta-button {
    background-color: $primary-color;
    color: $white;
    padding: 0.75rem 2rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $primary-hover;
    }
  }
}