@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');

.address-form-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  z-index: 1000;
  padding: clamp(0.5rem, 2vw, 2rem);
  backdrop-filter: blur(4px);
  
  .address-form {
    background: white;
    border-radius: clamp(0.5rem, 2vw, 1rem);
    padding: clamp(1rem, 3vw, 2.5rem);
    width: min(100%, 600px);
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    
    @media (prefers-reduced-motion: no-preference) {
      animation: slideIn 0.3s ease-out;
    }

    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: clamp(1rem, 2vw, 1.5rem);
      padding-bottom: clamp(0.75rem, 1.5vw, 1rem);
      border-bottom: 1px solid #e5e7eb;

      h2 {
        font-size: clamp(1.125rem, 1.5vw, 1.25rem);
        font-weight: 600;
        color: #111827;
        font-family: 'Libre Bodoni', serif;
        letter-spacing: 0.5px;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: #6b7280;
        cursor: pointer;
        padding: 0.25rem;
        border-radius: 0.375rem;
        transition: color 0.2s ease, background-color 0.2s ease, transform 0.2s ease;

        &:hover {
          background: #f3f4f6;
          color: #111827;
          transform: scale(1.05);
        }
      }
    }

    .form-grid {
      display: grid;
      gap: 1.75rem;
      grid-template-columns: 1fr;

      @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 5rem;
        position: relative;

        &.form-full {
          grid-column: 1 / -1;
        }

        &.checkbox-group {
          min-height: auto;
          margin-top: 0.5rem;
        }

        label {
          font-size: 0.875rem;
          font-weight: 500;
          color: #374151;
          font-family: 'Libre Bodoni', serif;
          margin-bottom: 0.25rem;
        }

        .error-message {
          font-size: 0.75rem;
          color: #ef4444;
          margin-top: 0.25rem;
          position: static;
          order: 3;
        }

        input,
        select {
          width: 100%;
          height: 2.5rem;
          padding: 0.625rem;
          border: 2px solid #d1d5db;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          color: #111827;
          background-color: white;
          transition: border-color 0.2s ease, box-shadow 0.2s ease;
          box-sizing: border-box;
          order: 2;
          margin: 0;

          &:hover:not(:disabled) {
            border-color: #9ca3af;
          }

          &:focus {
            outline: none;
            border-color: #2563eb;
            box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.15);
          }

          &::placeholder {
            color: #9ca3af;
          }

          &.error {
            border-color: #ef4444;
            
            &:focus {
              box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.15);
            }
          }

          &:disabled {
            background-color: #f3f4f6;
            cursor: not-allowed;
          }
        }

        select {
          appearance: none;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
          background-position: right 0.5rem center;
          background-repeat: no-repeat;
          background-size: 1.5em 1.5em;
          padding-right: 2.5rem;
        }
      }

      .checkbox-group {
        .checkbox-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          input[type="checkbox"] {
            width: 1rem;
            height: 1rem;
            margin: 0;
            cursor: pointer;
            border-radius: 0.25rem;
            min-height: auto;
            padding: 0;
            
            &:checked {
              background-color: #2563eb;
              border-color: #2563eb;
            }

            &:focus {
              box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.15);
            }
          }

          span {
            font-size: 0.875rem;
            color: #374151;
            font-family: 'Libre Bodoni', serif;
          }
        }
      }

      .form-actions {
        grid-column: 1 / -1;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 0.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #e5e7eb;

        @media (max-width: 480px) {
          flex-direction: column-reverse;
          
          button {
            width: 100%;
          }
        }

        button {
          padding: 0.625rem 1.25rem;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
          min-height: 2.5rem;

          &.cancel-btn {
            background: white;
            border: 2px solid #d1d5db;
            color: #4b5563;

            &:hover {
              background: #f3f4f6;
              border-color: #9ca3af;
            }

            &:focus {
              outline: none;
              box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.15);
            }
          }

          &.submit-btn {
            background: #2563eb;
            border: 2px solid #2563eb;
            color: white;
            min-width: 120px;

            &:hover {
              background: #1d4ed8;
              border-color: #1d4ed8;
            }

            &:focus {
              outline: none;
              box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.15);
            }

            &:disabled {
              background: #9ca3af;
              border-color: #9ca3af;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.saved-addresses {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  
  .saved-address {
    border: 2px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    
    &:hover {
      border-color: #2563eb;
      background: #f8fafc;
    }
    
    .address-type {
      font-size: 0.75rem;
      font-weight: 600;
      color: #4b5563;
      margin-bottom: 0.5rem;
      font-family: 'Libre Bodoni', serif;
    }
    
    .address-details {
      .name {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-family: 'Libre Bodoni', serif;
        letter-spacing: 0.3px;
      }
      
      p {
        margin: 0.125rem 0;
        font-size: 0.875rem;
        color: #374151;
      }
    }
    
    .default-badge {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      background: #dbeafe;
      color: #1e40af;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      font-weight: 500;
      font-family: 'Libre Bodoni', serif;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}