// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

// _colors.scss
$colors: (
  'teal': (
    'primary': #066469,
    'secondary': #379da3,
    'light': #f0fafb,
    'dark': #3a8a8f
  ),
  'base': (
    'white': #ffffff,
    'text': #333
  )
);

// Function to access colors
@function color($category, $shade: null) {
  @if $shade {
    @return map-get(map-get($colors, $category), $shade);
  }
  @return map-get($colors, $category);
}

// Reviews Component Styles
.reviews-container {
  font-family: $font-bodoni;
  padding: 3rem 2rem;
  background-color: color('teal', 'light');
  background-image: linear-gradient(
    45deg, 
    color('teal', 'light') 0%, 
    color('base', 'white') 100%
  );
  overflow: hidden;
}

.reviews-scroll {
  overflow: hidden;
  width: 100%;
  --scroll-width: 0px;
  --animation-duration: 60s;
}

.reviews-track {
  display: flex;
  width: calc(200% + var(--scroll-width));
  animation: scroll var(--animation-duration) linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - var(--scroll-width) / 2));
  }
}

.review-card {
  flex: 0 0 280px;
  background-color: color('base', 'white');
  border-radius: 1rem;
  box-shadow: 
    0 8px 16px rgba(color('teal', 'primary'), 0.1),
    0 4px 4px rgba(color('teal', 'primary'), 0.1);
  padding: 1.5rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 2px solid transparent;
  
  &:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 
      0 12px 24px rgba(color('teal', 'primary'), 0.2),
      0 8px 8px rgba(color('teal', 'primary'), 0.15);
    border-color: color('teal', 'secondary');
  }
}

.review-content {
  flex-grow: 1;
  margin-bottom: 1rem;
  position: relative;
  
  &::before {
    content: '"';
    font-size: 4rem;
    color: color('teal', 'secondary');
    opacity: 0.2;
    position: absolute;
    top: -1.5rem;
    left: -0.5rem;
  }
  
  p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: color('base', 'text');
    font-style: italic;
    position: relative;
    z-index: 1;
    max-height: 7.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.review-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid color('teal', 'light');
  padding-top: 1rem;
}

.review-icon {
  color: color('teal', 'primary');
  margin-right: 0.75rem;
  
  svg {
    height: 2em;
    width: 2em;
  }
}

.review-info {
  display: flex;
  flex-direction: column;
}

.review-name {
  font-weight: 700;
  font-size: 1rem;
  color: color('teal', 'dark');
  margin-bottom: 0.125rem;
}

.review-role {
  font-size: 0.8rem;
  color: color('teal', 'secondary');
  font-weight: 500;
}

@media (max-width: 768px) {
  .reviews-container {
    padding: 1.5rem 1rem;
  }
  
  .review-card {
    padding: 1.25rem;
    flex: 0 0 240px;
  }
}