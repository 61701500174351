// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

$primary-color: #066469;
$secondary-color: #f1faee;
$text-color: #000000;
$light-text-color: #666666;
$background-color: #fff;
$error-color: #ff0000;
$border-radius: 8px;

// Add a wrapper class for the main content
.page-wrapper {
  font-family: $font-bodoni;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.product-details-page {
  flex: 1;
  width: 100%;
  background-color: $background-color;
  color: $text-color;

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }

  .product-details {
    display: flex;
    gap: 30px;
    width: 100%;
    padding: 20px;

    .product-images {
      flex: 1;
      max-width: 50%;
      position: relative;
      
      .main-image-container {
        width: 93%;
        height: 600px;
        position: relative;
        overflow: hidden;
        margin: 0 auto 40px auto;
        border-radius: $border-radius;
        border: 1px solid rgba($primary-color, 0.2);
        touch-action: pan-y pinch-zoom;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          transition: transform 0.3s ease-out;
          user-select: none;
          -webkit-user-drag: none;
        }
    
        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(255, 255, 255, 0.8);
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          z-index: 2;
          transition: all 0.3s ease;
          opacity: 0;
          
          &:hover {
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          }
          
          &.prev {
            left: 10px;
          }
          
          &.next {
            right: 10px;
          }
          
          svg {
            width: 20px;
            height: 20px;
            color: $primary-color;
          }
        }
        
        .pagination-dots {
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 8px;
          z-index: 2;
          opacity: 0;
          
          .dot {
            width: 8px;
            height: 8px;
            border: none;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 0;
            cursor: pointer;
            transition: all 0.3s ease;
            
            &.active {
              background: $primary-color;
              border-color: $primary-color;
              transform: scale(1.2);
            }
          }
        }
    
        &:hover {
          .nav-button, .pagination-dots {
            opacity: 1;
          }
        }
      
      }
    
      

      .thumbnail-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        margin-bottom: 50px;

        .thumbnail {
          width: 80px;
          height: 80px;
          border-radius: $border-radius;
          cursor: pointer;
          transition: border-color 0.3s;
          border: 2px solid transparent;
          overflow: hidden;

          &.selected {
            border-color: $primary-color;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .product-info {
      flex: 1;
      padding: 10px;
      background-color: rgba($primary-color, 0.02);
      border-radius: $border-radius;

      h2 {
        font-size: 24px;
        margin-bottom: 15px;
        color: $text-color;
        font-weight: 600;
      }
      

      .price-container {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 4px;
        margin: 2px 0;
        padding: 8px 0;
        background-color: $background-color;
        border-radius: $border-radius;
  
        .sale-price, .original-price, .savings {
          margin: 0;
          line-height: 1;
        }
  
        .sale-price {
          font-size: 24px;
          font-weight: bold;
          color: $text-color;
        }
  
        .original-price {
          font-size: 14px;
          color: $light-text-color;
          text-decoration: line-through;
        }
  
        .savings {
          font-size: 14px;
          color: $primary-color;
          font-weight: 600;
        }
  
        // New styling for price wrapper
        .price-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-right: 8px;
          align-items: baseline;
        }
  
        .tax-info {
          width: 100%; // Force it to go to next line
          font-size: 11px; // Made even smaller
          color: $light-text-color;
          margin: 0;
          margin-top: 2px; // Tiny gap from price
          line-height: 1;
          padding-left: 1px; // Slight indent
        }
      }
    

      .options {
        margin-bottom: 25px;

        .option {
          h3 {
            font-size: 16px;
            margin-bottom: 12px;
            color: $text-color;
          }

          .option-buttons {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;

            .option-button {
              padding: 10px 20px;
              border: 1px solid $primary-color;
              border-radius: $border-radius;
              background-color: $background-color;
              color: $text-color;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background-color: rgba($primary-color, 0.1);
              }

              &.selected {
                background-color: $primary-color;
                color: $background-color;
              }

              &.color-button {
                width: 40px;
                height: 40px;
                padding: 0;
                border-radius: 50%;

                &.selected {
                  transform: scale(1.1);
                  box-shadow: 0 0 0 2px #fff, 0 0 0 4px $primary-color;
                }
              }
            }
          }
        }
      }

      .quantity {
        margin-bottom: 25px;

        h3 {
          font-size: 16px;
          margin-bottom: 12px;
          color: $text-color;
        }

        .quantity-selector {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: $background-color;
          padding: 5px;
          border-radius: $border-radius;
          width: fit-content;

          button {
            width: 30px;
            height: 30px;
            font-size: 18px;
            background-color: $primary-color;
            color: $background-color;
            border: none;
            border-radius: $border-radius;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: darken($primary-color, 10%);
            }
          }

          input {
            width: 50px;
            height: 30px;
            text-align: center;
            font-size: 16px;
            border: 1px solid rgba($primary-color, 0.2);
            border-radius: $border-radius;
            color: $text-color;

            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }

      .stock-warning {
        color: $error-color;
        font-size: 14px;
        margin: 10px 0;
        padding: 8px 12px;
        background-color: rgba($error-color, 0.1);
        border-radius: $border-radius;
        display: inline-block;
      }

      .action-buttons {
        display: flex;
        gap: 15px;
        margin: 30px 0;

        .add-to-cart, .buy-now {
          flex: 1;
          padding: 15px 30px;
          font-size: 16px;
          border: none;
          border-radius: $border-radius;
          cursor: pointer;
          font-weight: 600;
          transition: transform 0.3s, box-shadow 0.3s;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
        }

        .add-to-cart {
          background-color: $primary-color;
          color: $background-color;

          &:hover {
            background-color: darken($primary-color, 5%);
          }
        }

        .buy-now {
          background-color: darken($primary-color, 10%);
          color: $background-color;

          &:hover {
            background-color: darken($primary-color, 15%);
          }
        }
      }

      .product-about {
        margin-top: 30px;
        padding: 20px;
        background-color: $background-color;
        border-radius: $border-radius;
        border: 1px solid rgba($primary-color, 0.2);

        h3 {
          font-size: 18px;
          margin-bottom: 15px;
          color: $text-color;
          font-weight: 600;
          padding-bottom: 10px;
          border-bottom: 2px solid rgba($primary-color, 0.1);
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            position: relative;
            padding-left: 24px;
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 1.6;
            color: $text-color;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 8px;
              width: 8px;
              height: 8px;
              background-color: $primary-color;
              border-radius: 50%;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .description-section {
    width: 100%;
    background-color: rgba($primary-color, 0.02);
    padding: 60px 20px;
    
    .description-title {
      font-size: 24px;
      color: $text-color;
      margin-bottom: 30px;
      font-weight: 600;
      text-align: center;
    }
    
    .description-images {
      display: flex;
      flex-direction: column;
      gap: 20px; // Added larger gap between images
      max-width: 1800px;
      margin: 0 auto;
      
      .description-image-wrapper {
        width: 100%;
        
        
        img {
          width: 100%;
          height: auto;
          display: block;
          border-radius: $border-radius;
        }
      }
    }
  }
}

.star-rating {
  display: flex;
  align-items: center;
  background-color: $background-color;
  // padding: 8px 12px;
  border-radius: $border-radius;
  width: fit-content;

  .star {
    font-size: 18px;
    color: #ddd;
    margin-right: 2px;

    &.filled {
      color: $primary-color;
    }
  }

  .rating-value {
    margin-left: 8px;
    font-size: 14px;
    color: $light-text-color;
    font-weight: 500;
  }
}

.delivery-estimation {
  margin: 25px 0;
  padding: 20px;
  background-color: $background-color;
  border: 1px solid rgba($primary-color, 0.2);
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .delivery-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba($primary-color, 0.1);

    .icon-container {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($primary-color, 0.1);
      border-radius: 50%;

      .truck-icon {
        color: $primary-color;
        font-size: 20px;
      }
    }

    .header-text {
      h3 {
        font-size: 18px;
        color: $text-color;
        margin: 0 0 4px 0;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        color: $light-text-color;
        margin: 0;
      }
    }
  }

  .delivery-content {
    .pincode-input-container {
      position: relative;
      margin-bottom: 15px;
      width: 300px; // Fixed container width

      input {
        width: 100%; // Fill container
        min-width: 160px; // Minimum width
        max-width: 200px; // Maximum width
        padding: 12px 110px 12px 15px;
        border: 2px solid rgba($primary-color, 0.2);
        border-radius: $border-radius;
        font-size: 16px; // Increased to prevent iOS zoom
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        }

        &::placeholder {
          color: $light-text-color;
        }

        &.error {
          border-color: $error-color;
          transition: border-color 0.3s, box-shadow 0.3s;
        }
      }

      .check-button {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px 20px;
        border: none;
        border-radius: $border-radius - 2;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s;

        &:not(:disabled) {
          background-color: $primary-color;
          color: $background-color;

          &:hover {
            background-color: darken($primary-color, 5%);
          }
        }

        &:disabled {
          background-color: rgba($primary-color, 0.1);
          color: $light-text-color;
          cursor: not-allowed;
        }

        .loading-state {
          display: flex;
          align-items: center;
          gap: 8px;

          .spinner {
            width: 16px;
            height: 16px;
            border: 2px solid rgba($background-color, 0.3);
            border-top-color: $background-color;
            border-radius: 50%;
            animation: spin 0.8s linear infinite;
          }
        }
      }
    }

    .error-message {
      color: $error-color;
      font-size: 14px;
      margin-top: 8px;
    }

    .delivery-info {
      padding: 15px;
      border-radius: $border-radius;
      margin-top: 15px;
      transition: all 0.3s;

      &.available {
        background-color: rgba($primary-color, 0.05);
        border: 1px solid rgba($primary-color, 0.1);
      }

      &.unavailable {
        background-color: rgba($error-color, 0.05);
        border: 1px solid rgba($error-color, 0.1);
      }

      .info-content {
        .status-indicator {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;

          .status-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $primary-color;

            .unavailable & {
              background-color: $error-color;
            }
          }

          .status-text {
            font-weight: 600;
            color: $primary-color;

            .unavailable & {
              color: $error-color;
            }
          }
        }

        .delivery-details {
          margin-left: 18px;
          padding-left: 15px;
          border-left: 2px solid rgba($primary-color, 0.2);

          .estimation {
            color: $text-color;
            margin: 0 0 4px 0;
            font-size: 14px;

            span {
              font-weight: 600;
              color: $primary-color;
            }
          }

          .expected-date {
            font-size: 13px;
            color: $light-text-color;
            margin: 0;
          }

          .shipping-cost {
            font-size: 13px;
            color: $text-color;
            margin: 4px 0 0 0;
            font-weight: 500;
          }
        }
      }
    }
  }
}

// Responsive styles for delivery estimation
@media (max-width: 768px) {
  .delivery-estimation {
    padding: 15px;

    .delivery-content {
      .pincode-input-container {
        width: 100%;
        
        input {
          max-width: 180px;
        }

        .check-button {
          padding: 6px 15px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .delivery-estimation {
    padding: 12px;
    margin: 12px 0;

    .delivery-header {
      gap: 10px;

      .icon-container {
        min-width: 32px;
        height: 32px;

        .truck-icon {
          font-size: 16px;
        }
      }

      .header-text {
        h3 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .delivery-content {
      .pincode-input-container {
        input {
          max-width: 160px;
          padding: 10px 90px 10px 12px;
          font-size: 14px;
        }

        .check-button {
          padding: 6px 12px;
          font-size: 13px;

          .loading-state {
            .spinner {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .delivery-info {
        padding: 12px;

        .info-content {
          .status-indicator {
            .status-text {
              font-size: 14px;
            }
          }

          .delivery-details {
            margin-left: 15px;
            padding-left: 12px;

            .estimation {
              font-size: 13px;
            }

            .expected-date,
            .shipping-cost {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}


// Loading and Error States
.loading, .error, .not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 18px;
  color: $primary-color;
  text-align: center;
  padding: 20px;
  background-color: rgba($primary-color, 0.05);
  border-radius: $border-radius;
  margin: 20px 0;
  width: 100%;
}

.loading {
  &:after {
    content: '...';
    animation: dots 1.4s infinite;
  }
}

@keyframes dots {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%, 100% {
    content: '....';
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

// Responsive styles
@media (max-width: 1200px) {
  .product-details-page {
    .product-details {
      gap: 30px;
    }
  }
}

@media (max-width: 992px) {
  .product-details-page {
    .product-details {
      .product-images {
        max-width: 45%;

        .main-image-container {
          height: 500px;
        }
      }
    }

    .delivery-estimation {
      .delivery-content {
        .pincode-input-container {
          input {
            width: 40%;
          }
        }
      }
    }
  }
}

.service-benefits {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
  margin: 20px 0;
  background-color: rgba($primary-color, 0.02);
  border: 1px solid rgba($primary-color, 0.1);
  border-radius: $border-radius;

  .benefit-item {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    padding: 10px;
    
    .benefit-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: rgba($primary-color, 0.1);
      border-radius: 50%;
      
      svg {
        font-size: 20px;
        color: $primary-color;
      }
    }
    
    .benefit-text {
      display: flex;
      flex-direction: column;
      
      .benefit-title {
        font-size: 14px;
        font-weight: 600;
        color: $text-color;
      }
      
      .benefit-subtitle {
        font-size: 12px;
        color: $light-text-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .service-benefits {
    padding: 15px;
    gap: 10px;
    
    .benefit-item {
      padding: 8px;
      gap: 8px;
      
      .benefit-icon {
        width: 32px;
        height: 32px;
        
        svg {
          font-size: 16px;
        }
      }
      
      .benefit-text {
        .benefit-title {
          font-size: 13px;
        }
        
        .benefit-subtitle {
          font-size: 11px;
        }
      }
    }
  }

  .product-details-page {
    .product-details {
      flex-direction: column;
      padding: 15px;

      .product-images {
        max-width: 100%;
        order: 1;

        .main-image-container {
          height: 400px;
          margin-bottom: 10px;
        }

        .thumbnail-container {
          justify-content: center;
          margin-bottom: 15px;
        }
      }

      .product-info {
        order: 2;
        padding: 10px;
        h1 {
          margin-top: 0;  // Added to remove top margin
          margin-bottom: 10px;  // Added to control bottom spacing
        }

        .price-container {
          flex-wrap: wrap;
          gap: 6px;
        }

        .action-buttons {
          flex-direction: column;
        }
      }
    }

    .description-section {
      padding: 10px 15px;
      
      .description-images {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }

    .delivery-estimation {
      .delivery-content {
        .pincode-input-container {
          input {
            width: 40%;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .service-benefits {
    flex-direction: column;
    padding: 12px;
    
    .benefit-item {
      padding: 6px;
      
      .benefit-icon {
        width: 28px;
        height: 28px;
        
        svg {
          font-size: 14px;
        }
      }
      
      .benefit-text {
        .benefit-title {
          font-size: 12px;
        }
        
        .benefit-subtitle {
          font-size: 10px;
        }
      }
    }
  }

  .product-details-page {
    .product-details {
      padding: 10px;

      .product-images {
        .main-image-container {
          height: 380px;
        }

        .thumbnail-container {
          .thumbnail {
            width: 40px;
            height: 50px;
          }
        }
      }

      .product-info {
        padding: 10px;

        h1 {
          font-size: 20px;
        }

        .price-container {
          // padding: 10px;
          
          .sale-price {
            font-size: 24px;
          }
        }

        .options {
          .option-buttons {
            gap: 8px;
          }
        }
      }
    }

    .description-section {
      padding: 10px 10px;
      
      .description-title {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  

  .delivery-estimation {
    padding: 15px;
    margin: 15px 0;

    .delivery-header {
      gap: 10px;

      .icon-container {
        min-width: 32px;
        height: 32px;

        .truck-icon {
          font-size: 16px;
        }
      }

      .header-text {
        h3 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .delivery-content {
      .pincode-input-container {
        position: relative;
      margin-bottom: 15px;
      // Add max-width to constrain the container
      max-width: 400px;
        input {
          padding: 10px 90px 10px 12px;
          font-size: 14px;
        }

        .check-button {
          padding: 6px 12px;
          font-size: 13px;

          .loading-state {
            .spinner {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .delivery-info {
        padding: 12px;

        .info-content {
          .status-indicator {
            .status-text {
              font-size: 14px;
            }
          }

          .delivery-details {
            margin-left: 15px;
            padding-left: 12px;

            .estimation {
              font-size: 13px;
            }

            .expected-date,
            .shipping-cost {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .star-rating {
    // padding: 3px;

    .star {
      font-size: 16px;
    }

    .rating-value {
      font-size: 12px;
    }
  }
}

// For larger screens
@media (min-width: 1400px) {
  .product-details-page {
    .product-details {
      padding: 40px;
      gap: 60px;

      .product-images {
        .main-image-container {
          height: 700px;
        }
      }
    }

    .description-section {
      padding: 80px 40px;
      
      .description-images {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      }
    }
  }
}

