@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');

.footer {
  background-color: #066469;
  color: #e5e5e5;
  font-family: 'Poppins', sans-serif;
  padding: 4rem 0;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__logo {
    height: 48px;
    margin-bottom: 1.5rem;
  }

  &__newsletter-text {
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
  }

  &__form {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__input {
    flex: 1;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(229, 229, 229, 0.5);
    padding: 0.5rem 0;
    color: #e5e5e5;
    font-size: 0.95rem;

    &::placeholder {
      color: rgba(229, 229, 229, 0.7);
    }

    &:focus {
      outline: none;
      border-bottom-color: #e5e5e5;
    }
  }

  &__submit {
    background: transparent;
    border: none;
    color: #e5e5e5;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: 0.5rem;

    i {
      font-size: 1.1rem;
    }

    &:hover {
      color: #fff;
    }
  }

  &__subscribe-button {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    background: transparent;
    border: 1px solid rgba(229, 229, 229, 0.5);
    color: #e5e5e5;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(229, 229, 229, 0.1);
      border-color: #e5e5e5;
      color: #fff;
    }

    &:focus {
      outline: none;
      border-color: #e5e5e5;
    }
  }

  &__error {
    color: #ff6b6b;
    font-size: 0.85rem;
    margin: 0.5rem 0;
    text-align: left;
  }

  &__success {
    color: #51cf66;
    font-size: 0.85rem;
    margin: 0.5rem 0;
    text-align: left;
  }

  &__social {
    display: flex;
    gap: 1rem;
  }

  &__social-link {
    color: #e5e5e5;
    text-decoration: none;
    transition: color 0.3s ease;

    i {
      font-size: 1.1rem;
    }

    &:hover {
      color: #fff;
    }
  }

  &__heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.75rem;

      a {
        color: #e5e5e5;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  &__time {
    margin-top: 1.5rem !important;
  }

  &__address {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    line-height: 1.5;

    p {
      margin-bottom: 0.25rem;
    }
  }

  &__address-heading {
    font-weight: 500;
    margin-bottom: 0.5rem !important;
  }
}