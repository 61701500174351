// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

$primary-color: #066469;
$primary-dark: #066469;
$secondary-color: #3aa4a9;
$background-white: #ffffff;
$surface-color: #f8fafc;
$text-primary: #1a1f36;
$text-secondary: #4a5568;
$text-muted: #718096;
$border-color: #e2e8f0;
$success-color: #10b981;
$warning-color: #f59e0b;
$danger-color: #ef4444;
$shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
$shadow-md: 0 4px 6px rgba(0, 0, 0, 0.07);
$shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);

.product-card {
  font-family: $font-bodoni; 
  background: $background-white;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba($border-color, 0.6);
  box-shadow: $shadow-sm;

  &:hover {
    box-shadow: $shadow-lg;
    transform: translateY(-4px);
    
    .image-container img {
      transform: scale(1.02);
    }
  }

  .card-header {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    pointer-events: none;

    .badge {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      border-radius: 20px;
      font-size: 0.75rem;
      font-weight: 600;
      gap: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      
      .badge-icon {
        font-size: 0.875rem;
      }

      &.in-stock {
        background-color: rgba($success-color, 0.9);
        color: white;
      }

      &.low-stock {
        background-color: rgba($warning-color, 0.9);
        color: white;
      }

      &.out-stock {
        background-color: rgba($danger-color, 0.9);
        color: white;
      }
    }

    .discount-badge {
      background: linear-gradient(135deg, $success-color, darken($success-color, 10%));
      color: white;
      padding: 4px 10px;
      border-radius: 20px;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }

  .image-container {
    border-radius: 12px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
 overflow: hidden;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
  
  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(240,249,250,0.2) 0%, rgba(240,249,250,0.8) 100%);
    pointer-events: none;
  }

    .image-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $surface-color;
      color: $text-muted;

      .placeholder-icon {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .product-content {
    padding: 1rem;
    background: $background-white;
    flex: 1;
    display: flex;
    flex-direction: column;

    .product-title {
      font-size: 1rem;
      color: $text-primary;
      font-weight: 600;
      margin-bottom: 0.5rem;
      cursor: pointer;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.4;
      
      &:hover {
        color: $primary-color;
      }
    }

    .product-description {
      color: $text-secondary;
      font-size: 0.813rem;
      line-height: 1.5;
      margin-bottom: 0.75rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .product-details {
      background: $surface-color;
      border-radius: 8px;
      padding: 0.75rem;
      margin-bottom: 0.75rem;
    
      &.desktop-only {
        @media (max-width: 480px) {
          display: none;
        }
      }

      .specs-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem;
        margin-bottom: 0.5rem;
      }

      .spec-item {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .spec-label {
          color: $text-muted;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }

        .spec-value {
          color: $text-primary;
          font-size: 0.813rem;
          font-weight: 500;
        }

        &.fragrance {
          grid-column: span 2;
        }
      }
    }

    .price-block {
      margin: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;

      .price-info {
        .current-price {
          font-size: 1.25rem;
          font-weight: 700;
          color: $text-primary;
          display: flex;
          align-items: baseline;
          gap: 0.25rem;

          .currency {
            font-size: 0.875rem;
          }
        }

        .original-price {
          color: $text-muted;
          font-size: 0.75rem;
          margin-top: 0.25rem;
          
          .mrp-section {
            text-decoration: line-through;
            display: inline-block;
          }
    
          .tax-info {
            display: block;
            font-size: 0.7rem;
            color: $text-muted;
            margin-top: 2px;
            text-decoration: none;  // Explicitly remove line-through
          }
      }

    }

      .shipping-info {
        font-size: 0.75rem;
        color: $success-color;
        font-weight: 600;
      }
    }

    .action-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
      margin-top: 1rem;

      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.375rem;
        padding: 0.75rem 0.5rem;
        border-radius: 8px;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1;
        transition: all 0.2s ease;
        cursor: pointer;
        border: none;
        white-space: nowrap;
        min-height: 2.75rem;
        width: 100%;

        .button-icon {
          font-size: 1rem;
          flex-shrink: 0;
        }

        &:disabled {
          background: $text-muted;
          cursor: not-allowed;
          opacity: 0.7;
        }

        &.secondary {
          background: white;
          border: 1.5px solid $primary-color;
          color: $primary-color;

          &:hover:not(:disabled) {
            background: rgba($primary-color, 0.1);
          }
        }

        &.primary {
          background: linear-gradient(135deg, $primary-color, $primary-dark);
          color: white;
          
          &:hover:not(:disabled) {
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba($primary-color, 0.2);
          }

          &:active:not(:disabled) {
            transform: translateY(0);
          }
        }
      }
    }
  }
}



// Media Queries for Responsive Design
@media (max-width: 768px) {
  .product-card {
    .image-container {
      height: 200px;  // Reduced from 250px
      border-radius: 8px;
      margin: 0.5rem;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .product-content {
      padding: 0.75rem;  // Reduced padding

      .product-description {
        margin-bottom: 0.5rem;  // Reduced margin
      }

      .product-details {
        padding: 0.5rem;  // Reduced padding
        margin-bottom: 0.5rem;  // Reduced margin
      }
    }
  }
}

@media (max-width: 480px) {
  .product-card {
    .image-container {
      height: 150px;
      margin: 0.25rem;
    }

    .product-content {
      .product-title {
        font-size: 0.875rem; // Slightly smaller font
        line-height: 1.3; // Tighter line height
        -webkit-line-clamp: 3; // Show 3 lines instead of 2
        max-height: none; // Remove any max height restriction
        min-height: auto; // Allow natural height
      }
    }

    .action-buttons {
      display: grid;
      grid-template-columns: 1fr !important; // Force single column
      grid-template-rows: auto auto;
      gap: 0.75rem;
      margin-bottom: 0.5rem;

      button.secondary {
        border-width: 1px;
        order: 1; // Add to Cart first
      }

      button.primary {
        order: 2; // Buy Now second
      }
    }
  }
}

@media (max-width: 360px) {
  .product-card {
    .product-content {
      padding: 0.75rem;

      .product-title {
        font-size: 0.938rem;
        -webkit-line-clamp: 2;  // Keep it same as desktop
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.4;
      }

      .product-description {
        font-size: 0.75rem;
      }

      .price-block {
        .price-info {
          .current-price {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}