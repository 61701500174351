// Import Libre Bodoni font
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');

// Font variables
$font-primary: 'Libre Bodoni', serif;
$font-secondary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

.filter-component {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(84, 197, 203, 0.1), 0 1px 3px rgba(84, 197, 203, 0.08);
  padding: 24px;
  width: 100%;
  max-width: 350px;
  font-family: $font-secondary;

  .filter-title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #066469;
    margin-bottom: 20px;
    font-family: $font-primary;

    .filter-icon {
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2354c5cb'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z' /%3E%3C/svg%3E");
      background-size: contain;
      margin-right: 12px;
    }
  }

  .filter-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    label {
      font-size: 0.9rem;
      font-weight: 500;
      color: #024448;  // Darker teal
      font-family: $font-primary;
    }

    select, input[type="range"] {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #bfe8ea;  // Light teal
      border-radius: 6px;
      font-size: 0.9rem;
      color: #2c666a;  // Very dark teal
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      font-family: $font-secondary;

      &:focus {
        outline: none;
        border-color: #35c0c7;
        box-shadow: 0 0 0 3px rgba(84, 197, 203, 0.2);
      }
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2354c5cb'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: 16px;
      padding-right: 40px;
    }

    input[type="range"] {
      -webkit-appearance: none;
      height: 6px;
      background: #89ccd0;  // Light teal
      border-radius: 3px;
      outline: none;
      padding: 0;
      margin: 10px 0;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #54c5cb;
        cursor: pointer;
        transition: background .15s ease-in-out;

        &:hover {
          background: #3a8a8f;  // Darker teal
        }
      }

      &:active::-webkit-slider-thumb {
        background: #2c666a;  // Very dark teal
      }

      &::-moz-range-thumb {
        width: 18px;
        height: 18px;
        border: 0;
        border-radius: 50%;
        background: #43b6bc;
        cursor: pointer;
        transition: background .15s ease-in-out;

        &:hover {
          background: #3a8a8f;  // Darker teal
        }
      }

      &:active::-moz-range-thumb {
        background: #2c666a;  // Very dark teal
      }
    }

    .range-labels {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      color: #3a8a8f;  // Darker teal
      font-family: $font-secondary;
    }
  }
}