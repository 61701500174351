// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Variables
$primary-color: #066469;
$secondary-color: #3cadb3;
$text-color: #1f2937;
$light-gray: #f3f4f6;
$medium-gray: #9ca3af;
$dark-gray: #4b5563;
$error-color: #ef4444;
$success-color: #10b981;
$warning-color: #f59e0b;

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

// Breakpoints
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;

// Shadows
$card-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
$hover-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
$modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);

// Transitions
$transition-quick: 0.2s ease;
$transition-normal: 0.3s ease;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin card-style {
  background-color: white;
  border-radius: 12px;
  box-shadow: $card-shadow;
  padding: 1.25rem;
}

// Main Styles
.product-page {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  font-family: $font-bodoni;
  position: relative;
  background-color: #f9fafb;
  
  @media (max-width: $mobile) {
    padding: 0;
  }

  // Header Styles
  .header {
    @include flex-between;
    padding: 1.25rem;
    background-color: $primary-color;
    color: white;
    border-radius: 0;
    margin-bottom: 2rem;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: $card-shadow;

    @media (max-width: $mobile) {
      padding: 1rem;
      margin-bottom: 1rem;
    }

    h1 {
      margin: 0;
      font-size: 2rem;
      font-weight: 600;

      @media (max-width: $mobile) {
        font-size: 1.5rem;
      }
    }

    .mobile-filter-toggle {
      display: none;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 8px;
      transition: background-color $transition-quick;

      @media (max-width: $tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  // Main Content Layout
  .main-content {
    display: flex;
    gap: 2rem;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 1rem;
      padding: 0 0.5rem;
    }

    // Filter Container
    .filter-container {
      width: 280px;
      flex-shrink: 0;
      @include card-style;
      height: fit-content;
      position: sticky;
      top: 90px;
      transition: transform $transition-normal;

      @media (max-width: $tablet) {
        display: none;
        width: 90%;
        max-width: 400px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 90vh;
        z-index: 1000;
        margin: 0;
        padding: 1.5rem;
        overflow-y: auto;
        box-shadow: $modal-shadow;

        &.mobile-open {
          display: block;
          animation: slideIn $transition-normal;
        }
      }

      .filter-header {
        @include flex-between;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $light-gray;

        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          color: $text-color;
          margin: 0;
        }

        .close-filter {
          display: none;

          @media (max-width: $tablet) {
            display: block;
            background: none;
            border: none;
            color: $dark-gray;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 6px;
            transition: all $transition-quick;

            &:hover {
              background-color: $light-gray;
              color: $text-color;
            }
          }
        }
      }
    }

    // Product Area
    .product-area {
      flex-grow: 1;
      width: 100%;
    }
  }

  // Search and Sort Section
  .search-sort {
    @include card-style;
    @include flex-between;
    margin-bottom: 1.5rem;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }

    // Search Bar
    .search-bar {
      position: relative;
      flex: 1;
      max-width: 500px;
      margin-right: 1.5rem;

      @media (max-width: $tablet) {
        width: 100%;
        margin-right: 0;
      }

      input {
        width: 90%;
        padding: 0.75rem 2.5rem 0.75rem 1rem;
        border: 2px solid $light-gray;
        border-radius: 8px;
        font-size: 1rem;
        transition: all $transition-quick;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        }

        &::placeholder {
          color: $medium-gray;
        }
      }

      .search-icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $medium-gray;
        pointer-events: none;
      }
    }

    // Sort Dropdown
    .sort-dropdown {
      display: flex;
      align-items: center;

      @media (max-width: $tablet) {
        width: 100%;
      }

      span {
        margin-right: 0.75rem;
        font-weight: 500;
        color: $dark-gray;
      }

      select {
        padding: 0.75rem 2.5rem 0.75rem 1rem;
        border: 2px solid $light-gray;
        border-radius: 8px;
        font-size: 1rem;
        color: $text-color;
        background-color: white;
        cursor: pointer;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1rem;
        transition: all $transition-quick;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
        }

        @media (max-width: $tablet) {
          width: 100%;
        }
      }
    }
  }

  // Product Grid
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    padding: 0;

    @media (max-width: $desktop) {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    @media (max-width: $mobile) {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      gap: 1rem;
      padding: 0;
    }
  }

  // Mobile Overlay
  .mobile-overlay {
    display: none;
    
    @media (max-width: $tablet) {
      display: block;
      position: fixed;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
      backdrop-filter: blur(4px);
      animation: fadeIn $transition-normal;
    }
  }

  // Loading State
  .loading {
    @include flex-center;
    min-height: 400px;
    font-size: 1.2rem;
    color: $dark-gray;

    @media (max-width: $mobile) {
      min-height: 300px;
      font-size: 1rem;
    }
  }

  // Error State
  .error {
    @include flex-center;
    min-height: 400px;
    color: $error-color;
    font-size: 1.1rem;
    text-align: center;
    padding: 2rem;

    @media (max-width: $mobile) {
      min-height: 300px;
      font-size: 0.9rem;
      padding: 1rem;
    }
  }

  // Animations
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  // Scrollbar Styling
  .filter-container {
    scrollbar-width: thin;
    scrollbar-color: $medium-gray $light-gray;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $light-gray;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $medium-gray;
      border-radius: 3px;

      &:hover {
        background-color: darken($medium-gray, 10%);
      }
    }
  }

  // Accessibility
  *:focus-visible {
    outline: 2px solid $primary-color;
    outline-offset: 2px;
  }

  // Motion Reduction
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  // Touch Target Improvements for Mobile
  @media (max-width: $mobile) {
    button, 
    select, 
    input[type="checkbox"],
    input[type="radio"] {
      min-height: 44px;
      min-width: 44px;
      padding: 0.5rem;
    }

    input[type="range"] {
      height: 44px;
    }
  }
}