// Import Libre Bodoni font
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');

// Font variables
$font-primary: 'Libre Bodoni', serif;
$font-secondary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

// CustomerAttractionStrip.scss
// CustomerAttractionStrip.scss
.customer-attraction-strip {
  background: linear-gradient(to right, #3a8a8f, #54c5cb);
  height: 48px;
  overflow: hidden;
  position: relative;

  .strip-content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .phrases-container {
    display: flex;
    width: 100%;
    position: relative;
  }

  .phrase {
    color: white;
    font-weight: bold;
    padding: 0 20px;
    display: flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
    white-space: nowrap;

    .icon {
      margin-right: 8px;
      font-size: 20px;
    }
  }
}